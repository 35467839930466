import { BreadcrumbDefinition } from '@shared/modules/tr-breadcrumbs';
import { LayoutState, SidebarMenuItem } from '@app/_layouts';
import { AccessContext, AccessLevel } from '@common/enums';

import { CoreRoutePathEnum } from './core-route-path.enum';

export const CoreRouteBreadcrumbs: Record<CoreRoutePathEnum, BreadcrumbDefinition> = {
  [CoreRoutePathEnum.Dashboard]: {
    label: 'core.dashboard',
  },
  [CoreRoutePathEnum.InboundDocuments]: {
    label: 'text.inbound_docs',
  },
  [CoreRoutePathEnum.Articles]: {
    label: 'articles.title',
    parent: { label: 'core.sales', disable: true },
  },
  [CoreRoutePathEnum.Clients]: {
    label: 'clients.title',
    parent: { label: 'core.sales', disable: true },
  },
  [CoreRoutePathEnum.Invoices]: {
    skip: true,
  },
  [CoreRoutePathEnum.SalesLedger]: {
    label: 'text.sales_ledger',
    parent: { label: 'core.sales', disable: true },
  },
  [CoreRoutePathEnum.Vendors]: {
    label: 'vendors.title',
    parent: { label: 'core.purchases', disable: true },
  },
  [CoreRoutePathEnum.PurchaseLedger]: {
    label: 'text.purchase_ledger',
    parent: { label: 'core.purchases', disable: true },
  },
  [CoreRoutePathEnum.Expenses]: {
    label: 'core.expenses',
    parent: { label: 'core.expenses', disable: true },
  },
  [CoreRoutePathEnum.ExpenseEntity]: {
    label: 'entity',
    alias: 'entity',
    translate: false,
    parent: {
      label: 'core.expenses',
      routeInterceptor: (routeLink) => `${routeLink}s`,
      parent: { label: 'core.expenses', disable: true },
    },
  },
  [CoreRoutePathEnum.ExpenseCreate]: {
    label: 'system.new',
    displayAsBadge: true,
    parent: {
      label: 'core.expenses',
      routeInterceptor: (routeLink) => `${routeLink}/${CoreRoutePathEnum.Expenses}`,
      parent: { label: 'core.expenses', disable: true },
    },
  },
  [CoreRoutePathEnum.Persons]: {
    label: 'text.persons',
    parent: { label: 'core.expenses', disable: true },
  },
  [CoreRoutePathEnum.TransactionsBank]: {
    label: 'text.bank',
    parent: { label: 'core.transactions', disable: true },
  },
  [CoreRoutePathEnum.TransactionsCash]: {
    label: 'text.cash',
    parent: { label: 'core.transactions', disable: true },
  },
  [CoreRoutePathEnum.TransactionsOther]: {
    label: 'text.other',
    parent: { label: 'core.transactions', disable: true },
  },
  [CoreRoutePathEnum.Settlements]: {
    label: 'text.settlements',
    parent: { label: 'core.transactions', disable: true },
  },
  [CoreRoutePathEnum.Settlement]: {
    label: 'entity',
    alias: 'entity',
    skip: true,
    translate: false,
    parent: {
      label: 'text.settlements',
      routeInterceptor: (routeLink) => `${routeLink}/settlements`,
      parent: { label: 'core.transactions', disable: true },
    },
  },
  [CoreRoutePathEnum.FinancialEntryCreate]: {
    label: 'system.new',
    displayAsBadge: true,
    parent: {
      label: 'financial_entries.title',
      routeInterceptor: (routeLink) => `${routeLink}/${CoreRoutePathEnum.FinancialEntries}`,
      parent: { label: 'core.finance', disable: true },
    },
  },
  [CoreRoutePathEnum.FinancialEntryEntity]: {
    label: 'entity',
    alias: 'entity',
    skip: true,
    translate: false,
    parent: {
      label: 'financial_entries.title',
      routeInterceptor: (routeLink) => `${routeLink.replace('entry', 'entries')}`,
      parent: { label: 'core.finance', disable: true },
    },
  },
  [CoreRoutePathEnum.FinancialEntries]: {
    label: 'financial_entries.title',
    parent: { label: 'core.finance', disable: true },
  },
  [CoreRoutePathEnum.VatReports]: {
    label: 'core.vat_report',
    parent: { label: 'core.reports', disable: true },
  },
  [CoreRoutePathEnum.VDReports]: {
    label: 'core.vd_report',
    parent: { label: 'core.reports', disable: true },
  },
  [CoreRoutePathEnum.InvoiceReports]: {
    label: 'core.invoice_report',
    parent: { label: 'core.reports', disable: true },
  },
  [CoreRoutePathEnum.VatReportEntity]: {
    label: 'entity',
    alias: 'entity',
    skip: true,
    translate: false,
    parent: {
      label: 'core.vat_report',
      routeInterceptor: (routeLink) => `${routeLink}s`,
      parent: { label: 'core.reports', disable: true },
    },
  },
  [CoreRoutePathEnum.Ledger]: {
    label: 'invoice_ledger.title',
    parent: { label: 'core.reports', disable: true },
  },
  [CoreRoutePathEnum.TransactionReport]: {
    label: 'transaction_report.title',
    parent: { label: 'core.reports', disable: true },
  },
  [CoreRoutePathEnum.GeneralLedger]: {
    label: 'general_ledger.title',
    parent: { label: 'core.reports', disable: true },
  },
  [CoreRoutePathEnum.AnnualReports]: {
    label: 'reports.lbl_annual_reports',
    parent: { label: 'core.reports', disable: true },
  },
  [CoreRoutePathEnum.BalanceSheet]: {
    label: 'balance_sheet.title',
    parent: { label: 'core.reports', disable: true },
  },
  [CoreRoutePathEnum.ProfitAndLossSheet]: {
    label: 'profit_and_loss.title',
    parent: { label: 'core.reports', disable: true },
  },
  [CoreRoutePathEnum.StatementOfAccounts]: {
    label: 'core.statement_of_accounts',
    parent: { label: 'core.reports', disable: true },
  },
  [CoreRoutePathEnum.FixedAssets]: {
    label: 'text.fixed_assets',
    parent: { label: 'text.fixed_assets', disable: true },
  },
  [CoreRoutePathEnum.FixedAssetEntity]: {
    label: 'entity',
    alias: 'entity',
    skip: true,
    translate: false,
    parent: {
      label: 'text.fixed_assets',
      routeInterceptor: (routeLink) => `${routeLink}s`,
      parent: { label: 'text.fixed_assets', disable: true },
    },
  },
  [CoreRoutePathEnum.FixedAssetCreate]: {
    label: 'system.new',
    displayAsBadge: true,
    parent: {
      label: 'text.fixed_assets',
      routeInterceptor: (routeLink) => `${routeLink}/${CoreRoutePathEnum.FixedAssets}`,
      parent: { label: 'text.fixed_assets', disable: true },
    },
  },
  [CoreRoutePathEnum.Depreciation]: {
    label: 'text.depreciation',
    parent: { label: 'text.fixed_assets', disable: true },
  },
  [CoreRoutePathEnum.WriteOff]: {
    label: 'text.write_off',
    parent: { label: 'text.fixed_assets', disable: true },
  },
  [CoreRoutePathEnum.FixedAssetReport]: {
    label: 'text.fixed_asset_report',
    parent: { label: 'text.fixed_assets', disable: true },
  },
  [CoreRoutePathEnum.CompanySettings]: {
    label: 'text.company',
    parent: { label: 'system.settings', disable: true },
  },
  [CoreRoutePathEnum.VATRates]: {
    label: 'vat_rates.title',
    parent: { label: 'system.settings', disable: true },
  },
  [CoreRoutePathEnum.Accounts]: {
    label: 'accounts.title',
    parent: { label: 'system.settings', disable: true },
  },
  [CoreRoutePathEnum.PaymentMethods]: {
    label: 'text.payment_methods',
    parent: { label: 'system.settings', disable: true },
  },
  [CoreRoutePathEnum.OpeningBalances]: {
    label: 'text.opening_balances',
    parent: { label: 'system.settings', disable: true },
  },
};

export const CoreRouteSidebarMenuItems: SidebarMenuItem[] = [
  {
    label: 'core.dashboard',
    icon: 'dashboard',
    link: 'dashboard',
  },
  {
    label: 'text.inbound_docs',
    icon: 'accounting',
    link: 'inbound-documents',
    groupEnd: true,
  },
  {
    label: 'core.sales',
    icon: 'sales',
    link: 'sales',
    children: [
      {
        label: 'articles.title',
        link: 'articles',
        access: {
          context: AccessContext.Article,
          level: AccessLevel.List,
        },
      },
      {
        label: 'clients.title',
        link: 'clients',
        access: {
          context: AccessContext.Client,
          level: AccessLevel.List,
        },
      },
      {
        label: 'sales_invoices.title',
        link: 'invoice/sales',
        access: {
          context: AccessContext.SalesInvoice,
          level: AccessLevel.List,
        },
      },
      {
        label: 'text.sales_ledger',
        link: 'sales-ledger',
        access: {
          context: AccessContext.SalesInvoice,
          level: AccessLevel.List,
        },
      },
    ],
  },
  {
    label: 'core.purchases',
    icon: 'shopping-card',
    children: [
      {
        label: 'purchase_invoices.title',
        link: 'invoice/purchase',
        access: {
          context: AccessContext.PurchaseInvoice,
          level: AccessLevel.List,
        },
      },
      {
        label: 'vendors.title',
        link: 'vendors',
        access: {
          context: AccessContext.Vendor,
          level: AccessLevel.List,
        },
      },
      {
        label: 'text.purchase_ledger',
        link: 'purchase-ledger',
        access: {
          context: AccessContext.PurchaseInvoice,
          level: AccessLevel.List,
        },
      },
    ],
  },
  {
    label: 'core.expenses',
    icon: 'expenses',
    groupEnd: true,
    children: [
      {
        label: 'text.expenses',
        link: 'expenses',
        access: {
          context: AccessContext.Expense,
          level: AccessLevel.List,
        },
      },
      {
        label: 'text.persons',
        link: 'persons',
        access: {
          context: AccessContext.Person,
          level: AccessLevel.List,
        },
      },
    ],
  },
  {
    label: 'text.transactions',
    icon: 'transfer',
    children: [
      {
        label: 'text.bank',
        link: 'transactions/bank',
        access: {
          context: AccessContext.Transaction,
          level: AccessLevel.List,
        },
      },
      {
        label: 'text.cash',
        link: 'transactions/cash',
        access: {
          context: AccessContext.Transaction,
          level: AccessLevel.List,
        },
      },
      {
        label: 'text.other',
        link: 'transactions/other',
        access: {
          context: AccessContext.Transaction,
          level: AccessLevel.List,
        },
      },
      {
        label: 'text.settlements',
        link: 'settlements',
        access: {
          context: AccessContext.Transaction,
          level: AccessLevel.List,
        },
      },
    ],
  },
  {
    label: 'core.finance',
    icon: 'list-items',
    children: [
      {
        label: 'financial_registry.title',
        link: 'entries',
        access: {
          context: AccessContext.FinancialEntry,
          level: AccessLevel.List,
        },
      },
    ],
  },
  {
    label: 'core.reports',
    icon: 'reports',
    groupEnd: true,
    children: [
      {
        label: 'core.vat_report',
        link: 'vat-reports',
        access: {
          context: AccessContext.VATReport,
          level: AccessLevel.List,
        },
      },
      {
        label: 'core.vd_report',
        link: 'vd-reports',
        access: {
          context: AccessContext.VDReport,
          level: AccessLevel.List,
        },
      },
      {
        label: 'core.invoice_report',
        link: 'reports/invoice',
        // access: {
        //   context: AccessContext.VDReport,
        //   level: AccessLevel.List,
        // },
      },
      {
        label: 'invoice_ledger.title',
        link: 'ledger',
      },
      {
        label: 'general_ledger.sidebar',
        link: 'general-ledger',
        access: {
          context: AccessContext.GeneralLedger,
          level: AccessLevel.List,
        },
      },
      {
        label: 'transaction_report.title',
        link: 'transaction-report',
      },
      {
        label: 'reports.lbl_annual_reports',
        link: 'annual-reports',
        access: {
          context: AccessContext.AnnualReport,
          level: AccessLevel.List,
        },
      },
      {
        label: 'balance_sheet.title',
        link: 'balance-sheet',
        access: {
          context: AccessContext.BalanceSheet,
          level: AccessLevel.List,
        },
      },
      {
        label: 'profit_and_loss.title',
        link: 'profit-and-loss-sheet',
        access: {
          context: AccessContext.ProfitAndLoss,
          level: AccessLevel.List,
        },
      },
      {
        label: 'core.statement_of_accounts',
        link: 'statement-of-accounts',
        access: {
          context: AccessContext.GeneralLedger,
          level: AccessLevel.List,
        },
      },
    ],
  },
  {
    label: 'text.fixed_assets',
    icon: 'car',
    groupEnd: true,
    children: [
      {
        label: 'text.fixed_assets',
        link: 'fixed-assets',
        access: {
          context: AccessContext.FixedAsset,
          level: AccessLevel.List,
        },
      },
      {
        label: 'text.depreciation',
        link: 'depreciation',
        access: {
          context: AccessContext.FixedAsset,
          level: AccessLevel.List,
        },
      },
      {
        label: 'text.write_off',
        link: 'write-off',
        access: {
          context: AccessContext.FixedAsset,
          level: AccessLevel.List,
        },
      },
      {
        label: 'text.fixed_asset_report',
        link: 'fixed-asset-report',
        access: {
          context: AccessContext.FixedAsset,
          level: AccessLevel.List,
        },
      },
    ],
  },
  {
    label: 'system.settings',
    icon: 'settings',
    children: [
      {
        label: 'core.company',
        link: 'settings',
        // access: {
        //   context: '',
        //   level: 1,
        // },
      },
      {
        label: 'core.vat_rates',
        link: 'vat-rates',
        // access: {
        //   context: '',
        //   level: 1,
        // },
      },
      {
        label: 'core.accounts',
        link: 'accounts',
        access: {
          context: AccessContext.Account,
          level: AccessLevel.List,
        },
      },
      {
        label: 'text.payment_methods',
        link: 'payment-methods',
        access: {
          context: AccessContext.PaymentMethod,
          level: AccessLevel.List,
        },
      },
      {
        label: 'text.opening_balances',
        link: 'opening-balances',
        access: {
          context: AccessContext.OpeningBalance,
          level: AccessLevel.List,
        },
      },
    ],
  },
];

export const CoreRouteLayoutConfig: Partial<LayoutState> = {
  theme: null,
  showCompanyInfo: true,
  basePath: '',
  sidebar: {
    hidden: false,
    minimized: false,
    minimizeOnNavigation: false,
    menuItems: CoreRouteSidebarMenuItems,
  },
};
